import { useSearchParams } from 'src/routes/hooks';
import { getStorage } from 'src/hooks/use-session-storage';

// ----------------------------------------------------------------------

export function CheckTeam() {

  const state = getStorage('team');

  // let teamObject = initialState;
  const searchParams = useSearchParams();

  // 1) GET THE SESSION TEAM

  const storedTeamObject = state;
  const storedTeam = storedTeamObject?.team || '';
  // console.log('storedTeam', storedTeam);

  // 2) GET THE TEAM FROM URL
  // console.log('window.location.pathname', window.location.pathname);

  let currPath = window.location.pathname;

  const pathArray = currPath.split('/');
  // console.log('pathArray', pathArray);
  const is404 = pathArray[2] === '404';

  let urlTeam = (pathArray[2] !== '' && pathArray[2] !== '404' && pathArray[2] !== 'auth') ? pathArray[2] : '';
  // console.log('pathArray[3]', pathArray[3], pathArray[3] === '');
  // console.log('urlTeam 1', urlTeam);

  if (pathArray[3] === ''){
    currPath = `${currPath}home`;
  }
  // console.log('currPath', currPath);

  // console.log('searchParams.get("team")', searchParams.get("team"));
  if (!is404 && searchParams.get("team")){
    urlTeam = searchParams.get("team");
  }
  // console.log('urlTeam 2', urlTeam);
  // console.log('urlTeam', urlTeam);

  const teamsMatch = storedTeam === urlTeam;
  // console.log('storedTeam, urlTeam, teamsMatch, storedTeamObject, is404', storedTeam, urlTeam, teamsMatch, storedTeamObject, is404)
  return {storedTeam, urlTeam, teamsMatch, storedTeamObject, is404, currPath}
}


