import { useMemo, useEffect, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { GetButtons } from 'src/api_ballcharts/gets';
import { useTeam } from 'src/hooks/use-team';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => (
  // <SvgColor src={`${process.env.PUBLIC_URL}/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
   <Iconify icon={name} />
  // OR
  // <HomeIcon />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

/*
const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};
*/
const ICONS = {
  dashboard: icon('ant-design:home-twotone'),
  news: icon('ph:newspaper-clipping-duotone'),
  divisions: icon('fa-solid:list-ul'),
  rosters: icon('ph:users-three-duotone'),
  schedules: icon('ant-design:schedule-twotone'),
  calendars: icon('solar:calendar-bold-duotone'),
  stats: icon('ph:chart-bar-duotone'),
  standings: icon('fa-solid:list-ol'),
  photos: icon('bx:images'),
  videos: icon('bxs:videos'),
  links: icon('solar:link-bold-duotone'),
  contacts: icon('ant-design:contacts-twotone'),
  fields: icon('ic:twotone-map'),
  handouts: icon('ph:files-duotone'),
  sponsors: icon('bxs:donate-heart'),
  registrations: icon('fluent:check-24-filled'),
  store: icon('fluent:check-24-filled'),
  customs: icon('icon-park-twotone:web-page'),
  updates: icon('material-symbols:update'),
  admin: icon('ri:admin-fill'),
  chats: icon('vaadin:chat'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const team = useTeam();
  const { user } = useAuthContext();

  const [loadButtons, setLoadButtons] = useState(false);

  // console.log('loadButtons', loadButtons);
  const { buttonArray } = GetButtons(loadButtons && team.team, team.team);

  // console.log('buttonLoading', buttonLoading);
  
  useEffect(() => {
    setLoadButtons(true);
  }, []);

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: `${team.sport}`,
        items: [
          {
            title: 'Home',
            field: 'home',
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          // BALLCHARTS
          {
            title: 'News',
            field: 'news',
            path: paths.dashboard.news.root,
            icon: ICONS.news,
          },
          {
            title: 'Divisions',
            field: 'divisions',
            path: paths.dashboard.divisions,
            icon: ICONS.divisions,
          },
          {
            title: 'Roster',
            field: 'roster',
            path: paths.dashboard.rosters.root,
            icon: ICONS.rosters,
          },
          {
            title: 'Schedule',
            field: 'schedule',
            path: paths.dashboard.schedules.root,
            icon: ICONS.schedules,
          },
          {
            title: 'Calendar',
            field: 'calendar',
            path: paths.dashboard.calendars,
            icon: ICONS.calendars,
          },
          {
            title: 'Stats',
            field: 'stats',
            path: paths.dashboard.stats,
            icon: ICONS.stats,
          },
          {
            title: 'Standings',
            field: 'standings',
            path: paths.dashboard.standings,
            icon: ICONS.standings,
          },
          {
            title: 'Photos',
            field: 'photos',
            path: paths.dashboard.photos,
            icon: ICONS.photos,
          },
          {
            title: 'Videos',
            field: 'videos',
            path: paths.dashboard.videos,
            icon: ICONS.videos,
          },
          {
            title: 'Links',
            field: 'links',
            path: paths.dashboard.links,
            icon: ICONS.links,
          },
          {
            title: 'Contacts',
            field: 'contacts',
            path: paths.dashboard.contacts,
            icon: ICONS.contacts,
          },
          {
            title: 'Fields',
            field: 'fields',
            path: paths.dashboard.fields,
            icon: ICONS.fields,
          },
          {
            title: 'Handouts',
            field: 'handouts',
            path: paths.dashboard.handouts,
            icon: ICONS.handouts,
          },
          {
            title: 'Sponsors',
            field: 'sponsors',
            path: paths.dashboard.sponsors,
            icon: ICONS.sponsors,
          },
          {
            title: 'Registrations',
            field: 'registrations',
            path: paths.dashboard.registrations,
            icon: ICONS.registrations,
          },
          {
            title: 'Customs',
            field: 'customs',
            path: paths.dashboard.customs,
            icon: ICONS.customs,
          },
          { // THIS IS DELETE IF NOT LOGGED IN BELOW
            title: 'Chats',
            field: 'chats',
            path: paths.dashboard.chats.root,
            icon: ICONS.chats,
          },
          {
            title: t('ecommerce'),
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.ecommerce,
          },
          {
            title: t('analytics'),
            path: paths.dashboard.general.analytics,
            icon: ICONS.analytics,
          },
          {
            title: t('banking'),
            path: paths.dashboard.general.banking,
            icon: ICONS.banking,
          },
          {
            title: t('booking'),
            path: paths.dashboard.general.booking,
            icon: ICONS.booking,
          },
          {
            title: t('file'),
            path: paths.dashboard.general.file,
            icon: ICONS.file,
          },
        ],
      },
      { // THIS IS DELETE IF NOT ADMIN BELOW
        subheader: 'Admin',
        items: [
          {
            title: 'admin',
            path: paths.dashboard.admin.root,
            icon: ICONS.admin,
          },

        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      /*
      {
        subheader: t('management'),
        items: [
          // USER
          {
            title: t('user'),
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            children: [
              { title: t('profile'), path: paths.dashboard.user.root },
              { title: t('cards'), path: paths.dashboard.user.cards },
              { title: t('list'), path: paths.dashboard.user.list },
              { title: t('create'), path: paths.dashboard.user.new },
              { title: t('edit'), path: paths.dashboard.user.demo.edit },
              { title: t('account'), path: paths.dashboard.user.account },
            ],
          },

          // PRODUCT
          {
            title: t('product'),
            path: paths.dashboard.product.root,
            icon: ICONS.product,
            children: [
              { title: t('list'), path: paths.dashboard.product.root },
              {
                title: t('details'),
                path: paths.dashboard.product.demo.details,
              },
              { title: t('create'), path: paths.dashboard.product.new },
              { title: t('edit'), path: paths.dashboard.product.demo.edit },
            ],
          },

          // ORDER
          {
            title: t('order'),
            path: paths.dashboard.order.root,
            icon: ICONS.order,
            children: [
              { title: t('list'), path: paths.dashboard.order.root },
              { title: t('details'), path: paths.dashboard.order.demo.details },
            ],
          },

          // INVOICE
          {
            title: t('invoice'),
            path: paths.dashboard.invoice.root,
            icon: ICONS.invoice,
            children: [
              { title: t('list'), path: paths.dashboard.invoice.root },
              {
                title: t('details'),
                path: paths.dashboard.invoice.demo.details,
              },
              { title: t('create'), path: paths.dashboard.invoice.new },
              { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
            ],
          },

          // BLOG
          {
            title: t('blog'),
            path: paths.dashboard.post.root,
            icon: ICONS.blog,
            children: [
              { title: t('list'), path: paths.dashboard.post.root },
              { title: t('details'), path: paths.dashboard.post.demo.details },
              { title: t('create'), path: paths.dashboard.post.new },
              { title: t('edit'), path: paths.dashboard.post.demo.edit },
            ],
          },

          // JOB
          {
            title: t('job'),
            path: paths.dashboard.job.root,
            icon: ICONS.job,
            children: [
              { title: t('list'), path: paths.dashboard.job.root },
              { title: t('details'), path: paths.dashboard.job.demo.details },
              { title: t('create'), path: paths.dashboard.job.new },
              { title: t('edit'), path: paths.dashboard.job.demo.edit },
            ],
          },

          // TOUR
          {
            title: t('tour'),
            path: paths.dashboard.tour.root,
            icon: ICONS.tour,
            children: [
              { title: t('list'), path: paths.dashboard.tour.root },
              { title: t('details'), path: paths.dashboard.tour.demo.details },
              { title: t('create'), path: paths.dashboard.tour.new },
              { title: t('edit'), path: paths.dashboard.tour.demo.edit },
              { title: 'test', path: paths.dashboard.tour.demo.test },
            ],
          },

          // FILE MANAGER
          {
            title: t('file_manager'),
            path: paths.dashboard.fileManager,
            icon: ICONS.folder,
          },

          // MAIL
          {
            title: t('mail'),
            path: paths.dashboard.mail,
            icon: ICONS.mail,
            info: <Label color="error">+32</Label>,
          },

          // CHAT
          {
            title: t('chat'),
            path: paths.dashboard.chat,
            icon: ICONS.chat,
          },

          // CALENDAR
          {
            title: t('calendar'),
            path: paths.dashboard.calendar,
            icon: ICONS.calendar,
          },

          // KANBAN
          {
            title: t('kanban'),
            path: paths.dashboard.kanban,
            icon: ICONS.kanban,
          },
          {
            title: t('blank'),
            path: paths.dashboard.blank,
            icon: ICONS.blank,
          },

        ],
      },
      */
      // DEMO MENU STATES
      /*
      {
        subheader: t(t('other_cases')),
        items: [
          {
            // default roles : All roles can see this entry.
            // roles: ['user'] Only users can see this item.
            // roles: ['admin'] Only admin can see this item.
            // roles: ['admin', 'manager'] Only admin/manager can see this item.
            // Reference from 'src/guards/RoleBasedGuard'.
            title: t('item_by_roles'),
            path: paths.dashboard.permission,
            icon: ICONS.lock,
            roles: ['admin', 'manager'],
            caption: t('only_admin_can_see_this_item'),
          },
          {
            title: t('menu_level'),
            path: '#/dashboard/menu_level',
            icon: ICONS.menuItem,
            children: [
              {
                title: t('menu_level_1a'),
                path: '#/dashboard/menu_level/menu_level_1a',
              },
              {
                title: t('menu_level_1b'),
                path: '#/dashboard/menu_level/menu_level_1b',
                children: [
                  {
                    title: t('menu_level_2a'),
                    path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
                  },
                  {
                    title: t('menu_level_2b'),
                    path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
                    children: [
                      {
                        title: t('menu_level_3a'),
                        path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
                      },
                      {
                        title: t('menu_level_3b'),
                        path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: t('item_disabled'),
            path: '#disabled',
            icon: ICONS.disabled,
            disabled: true,
          },
          {
            title: t('item_label'),
            path: '#label',
            icon: ICONS.label,
            info: (
              <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
                NEW
              </Label>
            ),
          },
          {
            title: t('item_caption'),
            path: '#caption',
            icon: ICONS.menuItem,
            caption:
              'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
          },
          {
            title: t('item_external_link'),
            path: 'https://www.google.com/',
            icon: ICONS.external,
          },
          {
            title: t('blank'),
            path: paths.dashboard.blank,
            icon: ICONS.blank,
          },
        ],
      },
      */
    ],
    [t, team]
  );



  // console.log('buttonArray', buttonArray);
  // console.log('data', data);
  // console.log('team?.team in config-nav', team?.team);
  if (buttonArray === undefined || !team?.team){
    return [];
  }

  // console.log('user in nav', user);
  // console.log('data in nav', data);
  // REMOVE ADMIN IF NOT ADMIN
  if (!team?.MemberAdmin){
    delete data[1];
  }

  // REMOVE CHATS IF NOT LOGGED IN FOR THIS TEAM
    // console.log('memberTeamObject.confirmed', memberTeamObject?.confirmed);
    if (!user || !team?.MemberConfirmed){  // they are not confirmed for this team
      const filtered = data[0].items.filter((btn) => btn.field !== 'chats' || !btn.field);    
      // console.log('filtered', filtered);
      data[0].items = filtered;
      
    }

  // REMOVE CHATS FROM WEB FOR NOW
  const filtered = data[0].items.filter((btn) => btn.field !== 'chats' || !btn.field);    
  // console.log('filtered', filtered);
  data[0].items = filtered;


  // if (!buttonLoading){
    // console.log('buttonArray', buttonArray);
    // console.log('data[0].items', data[0].items);

    const myData = [...data[0].items];  

    // console.log('myData', myData);

    const newData = [];
    myData?.forEach((item, index) => {
      const thisItem = {...item};
      const { field } = thisItem;
      // console.log('field', field);
      const buttonItemOrder = buttonArray[field]?.order || undefined;
      const buttonItemLabel = buttonArray[field]?.butname || undefined;
      // const buttonItem = buttonObj.buttonArray[field];
      // console.log('buttonItem', buttonItem);
      // console.log('field buttonItemOrder', field, buttonItemOrder);
      if (buttonItemOrder) {
        thisItem.order = buttonItemOrder;
      }
      if (buttonItemLabel && buttonItemLabel !== thisItem.title) {
        thisItem.title = buttonItemLabel;
      }
      if (field === 'standings'){
        // CHECK IF SHOULD BE AN OUTSIDE LINK
        // console.log('standings button', team.league_link)
        if (team.league_link){
          thisItem.path = team.league_link;
        }
      }
      if (buttonArray[field] !== undefined || field === 'home'){
        newData.push(thisItem);
      } else if (field === 'customs'){
        // CHECK IF THERE ARY ANY BUTTONS WITH CUSTOM
        Object.entries(buttonArray).forEach(([buttonKey, buttonItem]) => {
          // console.log('buttonKey', buttonKey);
          // console.log('buttonItem', buttonItem);
          if (buttonKey.includes('custom')){
            const thisItemCustom = {...item};
            const customID = buttonKey.replace('custom', '');
            thisItemCustom.title = buttonItem.butname;
            thisItemCustom.order = buttonItem.order;
            thisItemCustom.url = buttonItem.url;
            let iconFixed = buttonItem?.icon?.replace('-', ':');
            if (!iconFixed){
              iconFixed = 'fa:file-o';
            }
            thisItemCustom.icon = icon(iconFixed);// buttonItem.icon;
            thisItemCustom.field = 'customs';
            thisItemCustom.customID = customID;
            if (thisItemCustom.url){
              thisItemCustom.path =  thisItemCustom.url;
            } else {
              thisItemCustom.path = paths.dashboard.customs(customID);
            }
            newData.push(thisItemCustom);
          }
        });
      }
    });    

    // console.log('newData', newData);

    newData.sort((a,b) => a.order - b.order);

    // console.log('newData sorted', newData);
    data[0].items = newData;

    // console.log('data sorted', data);

    data.reverse();  // REVERSE SO ADMIN AT TOP

  // }


  return data;
}
